.app {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.app__main {
  flex-grow: 1;
}

.html .body {
  -webkit-overflow-scrolling: touch !important;
  overflow: auto !important;
  height: 100% !important;
}
